var site = site || {};
site.client = site.client || {};

(function($, lazySizesConfig) {
  // Options can be set by declaring a global configuration option object named lazySizesConfig.
  // This object must be defined before the lazysizes script.

  // console.log('lazysizes-config loaded');
  // LazySizes initializes itself as soon as possible.
  // In case you set lazySizesConfig.init to false you need to explicitly call lazySizes.init().
  // lazySizesConfig.init = false;
  // Default 360 - Acceptable vales between 100 - 1000 (Small images, use small number.)
  lazySizesConfig.expand = 160;
  document.addEventListener('lazybeforeunveil', function(e) {
    var isMobile = site.client.isMobile;
    var bg = e.target.getAttribute('data-bg');
    if (isMobile && !!e.target.getAttribute('data-bg-mobile')) {
      bg = e.target.getAttribute('data-bg-mobile');
    }
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  });

  $('[data-match-height]').each(function() {
    let update = function() {
      if (typeof $.fn.matchHeight == 'function') {
        $.fn.matchHeight._update();
      }
    };
    this.addEventListener('load', update, true);
  });
})(jQuery, window.lazySizesConfig = window.lazySizesConfig || {});
